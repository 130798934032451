import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { SliceZone } from '@prismicio/react';

import Layout from '@/components/Layout';
import { Posts } from '@/components/Posts/Posts';
import useFilteredAndPaginatedPosts from '@/hooks/useFilteredAndPaginatedPosts';
import components from '../slices';

const CaseStudiesTemplate = ({ data, pageContext }) => {
  const {
    body: slices,
    body1: socialBody,
    splash_link_text: splashLinkText,
  } = data.prismicCaseStudiesPage.data;

  // html head data
  const headData = useMemo(
    () =>
      socialBody.map(({ primary: { description, image, title } }) => ({
        title: title.text,
        description: description.text,
        ogTitle: title.text,
        ogDescription: description.text,
        ogImageUrl: image.url,
      }))[0],
    [socialBody],
  );

  // all posts
  const posts = useMemo(() =>
    data.allPrismicCaseStudyPage.nodes.map((post) => ({
      url: post.url,
      image: post.data.case_study_background_image,
      title: post.data.case_study_headline,
      description: post.data.case_study_description,
      tags: [],
    })),
  );

  const { list, loadRef, filteredPosts, hasMore } =
    useFilteredAndPaginatedPosts(posts);

  return (
    <Layout
      {...headData}
      lang={pageContext.lang}
      altLangs={pageContext.alternate_languages}
    >
      <SliceZone
        slices={slices}
        components={components}
        context={{
          scrollLink: { to: 'posts', text: splashLinkText || 'See all posts' },
        }}
      />
      <Posts
        lang={data.prismicCaseStudiesPage.lang}
        filteredPosts={filteredPosts}
        list={list}
        hasMore={hasMore}
        ref={loadRef}
      />
    </Layout>
  );
};

export const query = graphql`
  query CaseStudiesQuery($lang: String, $uid: String) {
    prismicCaseStudiesPage(lang: { eq: $lang }, uid: { eq: $uid }) {
      _previewable
      lang
      alternate_languages {
        uid
        type
        lang
      }
      data {
        splash_link_text
        body1 {
          ... on PrismicCaseStudiesPageDataBody1GeneralCard {
            primary {
              description {
                text
              }
              title {
                text
              }
              image {
                url
              }
            }
          }
          ... on PrismicCaseStudiesPageDataBody1TwitterCard {
            primary {
              description {
                text
              }
              title {
                text
              }
              image {
                url
              }
            }
          }
        }
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...CaseStudiesPageSplashSection
          ...CaseStudiesPageFeaturedPosts
        }
      }
    }

    allPrismicCaseStudyPage(
      filter: { lang: { eq: $lang } }
      sort: { order: DESC, fields: first_publication_date }
    ) {
      nodes {
        url
        data {
          case_study_description {
            richText
          }
          case_study_headline {
            richText
          }
          case_study_background_image {
            alt
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(CaseStudiesTemplate);
